import { render, staticRenderFns } from "./AsideAcessoRapido.vue?vue&type=template&id=97e6e66c&scoped=true&lang=pug&"
import script from "./AsideAcessoRapido.vue?vue&type=script&lang=js&"
export * from "./AsideAcessoRapido.vue?vue&type=script&lang=js&"
import style0 from "./AsideAcessoRapido.styl?vue&type=style&index=0&id=97e6e66c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e6e66c",
  null
  
)

export default component.exports